<template>
    <div class="comment_modify_wrap">
<!--        <div class="modify_text">-->
<!--            아린이에게-->
<!--            아린이는 의사가 되고 싶구나. 아저씨가 적극 응원할게요. 하얀 가운을 입고 아픈사람을 치료하고, 또 병에 걸리지 않도록 예방하는 일을 하게 되겠네. 그리고 일부 의사 선생님들은 생활환경이 매우-->
<!--            열악한 저개발국 등에서 그 나라 사람들의 질병퇴치를 위하여, 엄청난 어려움과 위험 속에서도 활동하고 계시기도 하지.-->
<!--        </div>-->
        <input type="textarea" class="modify_text" rows="50">

        </input>
        <div class="modify_footer">
            <div class="max_length"><span class="typed">157</span>/1000</div>
            <button class="modify_btn">수정</button>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "CommentInputLetterModifyLayout",
        mixins: [],
        components: {},
        props: {
            setData: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                commentTxt: ''
            }
        },
        beforeRouterEnter() {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeRouterLeave() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        computed: {},
        methods: {},
        watch: {},
    }
</script>

<style scoped>

</style>